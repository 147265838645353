import React from "react"
import '../style.css'
import { FaFileDownload } from "@react-icons/all-files/fa/FaFileDownload";

const DownloadButton = ({text,link,buttonStyles}) => {

  //Component for download button, for ease of reuse

  return (
    <a href={link} target="_blank" rel="noreferrer" className={buttonStyles} style={{ display:'flex', justifyContent:'center'}}>
        <div >
            <p style={{color:'#facb65', textAlign:'center', marginBottom:'0'}}>{text} <FaFileDownload style={{width:'0.9em', margin:'5px'}}/></p>
        </div>
    </a>
  )
}

DownloadButton.defaultProps = {
    text:'Download PDF',
    link:'/',
    buttonStyles:'media-item-buttons'
}

export default DownloadButton