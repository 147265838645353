import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import DownloadButton from "../../components/downloadButton";

const FRCG = () => {
    const ButtonStylesClass = 'poster-item-buttons'       

    const posters = [
        {
            image:'/sat.jpg',
            title:'Fermi Satellite',
            description:'NASA’s Fermi satellite was launched into low-Earth orbit on June 11, 2008, and continues to map the entire gamma-ray sky every three hours. The satellite’s name honors the famous Italian-American physicist Enrico Fermi, who first proposed the eponymous mechanism for the acceleration of cosmic rays that creates some of the high-energy gamma rays that the satellite detects. NASA’s Fermi mission is an international collaboration between astrophysicists and particle physicists from the United States, Germany, Italy, Japan and Sweden. Fermi observes a wide window of the gamma-ray spectrum using two instruments: the Gamma-ray Burst Monitor (GBM) and the Large Area Telescope (LAT).',
        },
        {
            image:'/map.jpg',
            title:'Fermi Skymap',
            description:'Extremely bright emission from the plane of the Milky Way galaxy dominates the high-energy gamma-ray sky. These gamma rays form when cosmic ray particles interact with our Galaxy’s dust, gas and starlight. Several sources embedded in the glowing gas shine brightly — most are pulsars, rotating stellar corpses with densities like those of atomic nuclei. A multitude of other sources can be seen above and below the Galactic plane. These are primarily blazars — the nuclei of active galaxies that emit bright jets of gamma rays directly aimed at Earth. Fermi has also discovered new classes of high-energy gamma-ray emitters, including high-mass binary systems, normal galaxies, supernova remnants and novae. (click on images for a larger view)',
        },
        {
            image:'/merge.jpg',
            title:'Merging Neutron Stars',
            description:'The coalescence of two neutron stars into a black hole has long been theorized to be the source of short duration gamma-ray bursts (GRBs). Direct proof supporting this scenario, however, was first obtained on Aug. 17, 2017, when Fermi’s GBM reported a short, relatively weak GRB in conjunction with gravitational wave signals detected by the National Science Foundation’s Laser Interferometer Gravitational-wave Observatory (LIGO) and the European Virgo observatory, located near Pisa, Italy. Detailed follow-up studies of this first neutron star merger were conducted by thousands of astronomers world-wide, using data from over 70 different instruments and observatories. Analysis of the visible and ultraviolet light spectra of the merger’s afterglow radiation provided evidence for the creation of gold and other elements heavier than iron. (click on images for a larger view)',
        },
        {
            image:'/agn.jpg',
            title:'Active Galaxies',
            description:'Supermassive black holes within the cores of distant galaxies form a ubiquitous class of high-energy gamma sources. Black holes with masses millions to billions times that of our Sun power jets of particles that can extend thousands of light-years. If a gamma-ray jet is emitted along our line of sight from Earth, the object is known as a blazar and is readily detected by NASA’s Fermi satellite. To date, over 3,000 gamma-ray blazars have been located by Fermi. Surprisingly large and rapid flares in gamma-ray intensity have been observed from many blazars, and multiwavelength data in radio, X-ray and visible light contribute to differing physical interpretations of the particles and acceleration mechanisms in the jets. (click on images for a larger view)',
        },
        {
            image:'/snr.jpg',
            title:'Supernova Remnants',
            description:'Supernova remnants are “star wrecks,” the expanding debris from titanic explosions that mark the end of a massive star’s life. Many supernova remnants observed in visible, X-ray and radio light show “limb brightening,” where their outer edges are more luminous than the evacuated central regions. L limb brightening comes from shock waves that form as the rapidly moving material expands into surrounding regions of lower densities. Fermi observations have provided compelling new evidence that these shocks can accelerate charged cosmic- ray particles to energies sufficient to create the observed high-energy gamma rays. (click on images for a larger view)',
        },
        {
            image:'/bubble.jpg',
            title:'Fermi Bubbles',
            description:'The Fermi Bubbles are nearly symmetrical diffuse gamma-ray lobes extending above and below the central part of the Milky Way’s plane. They span over 50,000 light years in length. Discovered in 2010, the Fermi Bubbles seem to correlate with features in microwave skymaps (the so-called WMAP haze) and possibly also in X-rays, suggesting that high-energy particles were somehow injected into the region, sometime in the past few million years. The injection process must have occurred over a relatively short time because the bubbles have well-defined edges. Many theoretical explanations for the Fermi Bubbles invoke outbursts from the supermassive black hole at the Milky Way’s core. (click on images for a larger view)',
        },
    ]

    return (
        <Layout>
            <div>
                <h1>Fermi Art Posters</h1>
                <Star />
                <p>The posters on this page were created by Aurore Simonnet, to illustrate the beauty of the Fermi Gamma-ray Space Telescope and its study of the gamma-ray Universe. To reuse any of these images, please use the credit: NASA/Fermi Gamma-ray Space Telescope/Sonoma State University/Aurore Simonnet.</p>
                <div className="poster-container">
                    {posters.map( (poster,indx) => 
                        <div className="single-poster-container" key={indx}>
                            <a href={poster.image} target='_blank' rel="noreferrer">
                                <img className="poster-image" src={poster.image} alt='poster'/>
                            </a>
                            <h2>{poster.title}</h2>
                            <p style={{textAlign:'start'}}>{poster.description}</p>
                            <DownloadButton text={poster.title} link={poster.image} buttonStyles = {ButtonStylesClass}/>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default FRCG